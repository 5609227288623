<template>
    <div class="page">
        <TitleHeader :title="title" :link="'/mission-bank'" />

        <div class="px-3">
            <div class="btn bg-sub text-primary w-100">
                Lorem ipsum
            </div>
            <CateBtns :list="cate" />
        </div>
    </div>
</template>

<script>
import TitleHeader from '@/components/common/TitleHeader.vue'
import CateBtns from '@/components/common/CateBtns.vue'

export default {
    components:{
        TitleHeader,
        CateBtns
    },
    data(){
        return{
            title: 'Q&A BANK',
            cate: [
                {
                    title: '쿠폰<br>사용법',
                    link: '/'
                },
                {
                    title: '쿠폰<br>정책',
                    link: '/'
                },
                {
                    title: '제휴점<br>가입',
                    link: '/'
                },
                {
                    title: '창업<br>문의',
                    link: '/'
                },
                {
                    title: '리워드<br>정책',
                    link: '/'
                },
                {
                    title: '기타<br>문의',
                    link: '/'
                },
            ]
        }
    }

}
</script>
