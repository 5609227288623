<template>
    <div>
        <ul class="basic_list d-flex flex-wrap flex-column mt-4 last-border-0" v-if="list.length != 0">
            <li v-for="(item,index) in list" :key="index" class="d-flex justify-content-between border-bottom py-3" >
                <router-link :to="`/alert/${item.idx}`" class="left d-flex">
                    <div class="album_info d-flex flex-column justify-content-center">
                        <span class="mb-2 text-truncate">{{ item.title }}</span>
                        <small class="text-truncate-2 lh-sm">{{ item.desc }}</small>
                    </div>
                </router-link>
                
                <div class="right d-flex my-3">
                    <button class="btn"><i class="fas fa-trash text-gray-400"></i></button>
                </div>
            </li>
        </ul>
        <div class="empty_data" v-else>
            <p class="text-center py-5">
                알림이 없어요.
            </p>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            list: [
                {
                    idx: 0,
                    title: 'lorem ipsum',
                    desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A atque eveniet qui dolorum. Corrupti eligendi tempora consequuntur culpa perferendis tenetur pariatur eaque aliquam, nam totam sint impedit officiis, quasi ducimus.',
                },
                {
                    idx: 0,
                    title: 'lorem ipsum',
                    desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A atque eveniet qui dolorum. Corrupti eligendi tempora consequuntur culpa perferendis tenetur pariatur eaque aliquam, nam totam sint impedit officiis, quasi ducimus.',
                },
                {
                    idx: 0,
                    title: 'lorem ipsum',
                    desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A atque eveniet qui dolorum. Corrupti eligendi tempora consequuntur culpa perferendis tenetur pariatur eaque aliquam, nam totam sint impedit officiis, quasi ducimus.',
                },
            ],
        }
    },
}
</script>
