<template>
    <div class="page">
        <TitleHeader :title="title" :link="'/mission-bank'" />

        <div class="px-3">
            <div class="btn bg-sub text-primary w-100 flex-between-center mb-2">
                <span>광고 수신량</span>
                <span>총 195건</span>
            </div>
            <div class="btn bg-green text-green w-100 flex-between-center mb-2">
                <span>광고 시청량</span>
                <span>총 153건</span>
            </div>
            <div class="btn bg-gray-100 text-gray w-100 flex-between-center">
                <span>미시청광고수량</span>
                <span>총 45</span>
            </div>
            <ul class="py-3">
                <li v-for="(item,index) in list" :key="index" class="mb-3 border shadow-sm rounded overflow-hidden">
                    <div class="d-flex flex-column">
                        <div class="py-2 px-3 bg-dark text-white">{{ item.title}}</div>
                        <div class="d-flex justify-content-between align-items-center w-100 p-2">
                            <div class="text-gray-600 fs-px-14">
                                시청제한 : {{item.limit}}명<br>
                                경품수량 : {{ item.gift }}<br>
                                잔여경품 : {{item.remain}}
                            </div>
                            <div class="d-flex flex-column">
                                <button class="btn btn-sm btn-danger mb-2 lh-sm" @click="$router.push('/ad-mission/0')">광고<br>보기</button>
                                <button class="btn btn-sm bg-gray-200 py-0"><small>{{item.name}}</small></button>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import TitleHeader from '@/components/common/TitleHeader.vue'

export default {
    components:{
        TitleHeader,
    },
    data(){
        return{
            title: 'Ads BANK',
            list: [
                {
                    type:'video',
                    title: '초저가 공동구매 - 삼성 TV',
                    limit:500000,
                    gift:'TV 등 2,000점',
                    remain:'1430점',
                    name:'홍길동',
                },
                {
                    type:'video',
                    title: '세계 1등 에어컨 - LG전자',
                    limit:500000,
                    gift:'TV 등 2,000점',
                    remain:'1430점',
                    name:'홍길동',
                },
                {
                    type:'video',
                    title: '사람이 미래다 - 두산 그룹',
                    limit:500000,
                    gift:'TV 등 2,000점',
                    remain:'1430점',
                    name:'홍길동',
                },
                {
                    type:'video',
                    title: '요리주문 - 요기요',
                    limit:500000,
                    gift:'TV 등 2,000점',
                    remain:'1430점',
                    name:'홍길동',
                },
                {
                    type:'video',
                    title: '숙박예약 - 야놀자',
                    limit:500000,
                    gift:'TV 등 2,000점',
                    remain:'1430점',
                    name:'홍길동',
                },                
            ]
        }
    }

}
</script>
