<template>
    <div class="page">
        <TitleHeader :title="title" :link="'/'"/>
        <div class="py-3 px-4 mx-3 rounded-4 mb-3 bg-gray-100">
            <h5 class="text-primary mb-2">Mission bank</h5>
            <p>유저들의 노래 가창수익 외 별도의 리워드를 제공해주는 콘텐츠와 각종 정보 안내를 해주는 코너입니다. </p>
        </div>
        <div class="py-3 px-4 mx-3 rounded-4 mb-3 bg-sub">
            <h5 class="text-primary mb-2 text-center">오늘 새로 개설된 미션</h5>
            <p class="text-center">검은 사막 모바일 앱 다운로드<br>
                <b class="text-danger">5,000 포인트 리워드 제공</b> </p>
        </div>

        <div class="px-3">
            <div class="flex-between-center gap-2">
                <router-link to="/ad-mission" class="border border-width-px-2 border-primary shadow-sm rounded-2 w-25 ratio ratio-1x1 position-relative">
                    <div class="inner flex-center-center text-center">
                        <div class="lh-sm text-primary fw-bold mt-1">광고<br>미션</div>
                    </div>
                </router-link>
                <router-link to="/servey-mission" class="border border-width-px-2 border-primary shadow-sm rounded-2 w-25 ratio ratio-1x1 position-relative">
                    <div class="inner flex-center-center text-center">
                        <div class="lh-sm text-primary fw-bold mt-1">설문<br>미션</div>
                    </div>
                </router-link>
                <span @click="$alert('준비중 입니다.')" class="border border-width-px-2 border-primary shadow-sm rounded-2 w-25 ratio ratio-1x1 position-relative">
                    <div class="inner flex-center-center text-center">
                        <div class="lh-sm text-primary fw-bold mt-1">앱설치<br>미션</div>
                    </div>
                </span>
                <span @click="$alert('준비중 입니다.')" class="border border-width-px-2 border-primary shadow-sm rounded-2 w-25 ratio ratio-1x1 position-relative">
                    <div class="inner flex-center-center text-center">
                        <div class="lh-sm text-primary fw-bold mt-1">카드<br>미션</div>
                    </div>
                </span>
            </div>
            <hr class="border-gray-500">
            
            <div class="flex-between-center gap-3 mb-3">
                <router-link to="/notice-bank" class="border rounded-2 w-25 ratio ratio-1x1 position-relative">
                    <div class="inner flex-center-center text-center">
                        <div class="lh-sm">알림<br>BANK</div>
                    </div>
                </router-link>
                <router-link to="/business-bank" class="border rounded-2 w-25 ratio ratio-1x1 position-relative">
                    <div class="inner flex-center-center text-center">
                        <div class="lh-sm">창업<br>BANK</div>
                    </div>
                </router-link>
                <router-link to="/partner-bank" class="border rounded-2 w-25 ratio ratio-1x1 position-relative">
                    <div class="inner flex-center-center text-center">
                        <div class="lh-sm">제휴점<br>BANK</div>
                    </div>
                </router-link>
                <router-link to="/qna-bank" class="border rounded-2 w-25 ratio ratio-1x1 position-relative">
                    <div class="inner flex-center-center text-center">
                        <div class="lh-sm">Q&A<br>BANK</div>
                    </div>
                </router-link>
            </div>

            <div class="flex-between-center gap-3">
                <router-link to="/shopping-bank" class="border rounded-2 w-25 ratio ratio-1x1 position-relative">
                    <div class="inner flex-center-center text-center">
                        <div class="lh-sm">쇼핑<br>BANK</div>
                    </div>
                </router-link>
                <router-link to="/tour-bank" class="border rounded-2 w-25 ratio ratio-1x1 position-relative">
                    <div class="inner flex-center-center text-center">
                        <div class="lh-sm">TOUR<br>BANK</div>
                    </div>
                </router-link>
                <router-link to="/game-bank" class="border rounded-2 w-25 ratio ratio-1x1 position-relative">
                    <div class="inner flex-center-center text-center">
                        <div class="lh-sm">게임<br>BANK</div>
                    </div>
                </router-link>
                <router-link to="/gift-bank" class="border rounded-2 w-25 ratio ratio-1x1 position-relative">
                    <div class="inner flex-center-center text-center">
                        <div class="lh-sm">경품<br>BANK</div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import TitleHeader from '@/components/common/TitleHeader.vue'

export default {
    components:{
        TitleHeader,
    },
    data(){
        return{
            title: 'mission BANK'
        }
    }

}
</script>
