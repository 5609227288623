<template>
    <div class="page">
        <TitleHeader :title="title" :link="'/mission-bank'" />

        <div class="px-3">
            <div class="btn bg-sub text-primary w-100 flex-between-center mb-4">
                <span>경품 당첨 수량</span>
                <span>총 15건</span>
            </div>
            <h5 class="mt-3 mb-2 px-1">경품 당첨 내역</h5>

            <div class="">
                <table class="w-100">
                    <thead>
                        <tr class="text-center text-gray-600 fw-500 small">
                            <th class="py-2">선택</th>
                            <th class="py-2">당첨일</th>
                            <th class="py-2">경품명</th>
                            <th class="py-2">당첨수량</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in list" :key="index" class="text-center hover-bg-gray-100 rounded" @click="item.check = !item.check">
                            <td class="py-2">
                                <input type="checkbox" class="form-check-input" v-model="item.check">
                            </td>
                            <td class="py-2">{{item.date}}</td>
                            <td class="py-2">{{item.name}}</td>
                            <td class="py-2"><b>{{item.count}}</b>건</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="bottom_box position-fixed max-w-px-768 bottom-0 start-0 w-100 p-3 bg-white">
            <div class="mb-2">
                <router-link to="/" class="w-100 px-3 btn border border-primary py-px-12 mb-2">경품 거래소 등록하기</router-link>
                <router-link to="/" class="w-100 px-3 btn bg-main text-white py-px-12 mb-2">경품 응모하기</router-link>
                <router-link to="/" class="w-100 px-3 btn bg-gray-200 py-px-12">경품 배송신청하기</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import TitleHeader from '@/components/common/TitleHeader.vue'
import CateBtns from '@/components/common/CateBtns.vue'

export default {
    components:{
        TitleHeader,
        CateBtns
    },
    data(){
        return{
            title: '경품 BANK',
            list: [
                {
                    date: '23.6.05',
                    name: '로또 응모권',
                    count: 80,
                    check: false,
                },
                {
                    date: '23.6.05',
                    name: '로또 응모권',
                    count: 80,
                    check: false,
                },
                {
                    date: '23.6.05',
                    name: '로또 응모권',
                    count: 80,
                    check: false,
                },
            ]
        }
    }

}
</script>
