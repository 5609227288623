<template>
    <div class="flex-evenly-center gap-3 py-3 flex-wrap" v-if="list != null">
        <div class="border border-width-px-2 border-primary shadow-sm rounded-2 w-25 ratio ratio-1x1 position-relative" v-for="(item,index) in list" :key="index">
            <router-link :to="item.link" class="" :key="index" v-if="item.link != '/'">
                <div class="inner h-100 flex-center-center text-center text-primary">
                    <div v-html="item.title" class="lh-sm mt-1"></div>
                </div>
            </router-link>
            <div @click="$alert('준비중 입니다.')" v-else>
                <div class="inner h-100 flex-center-center text-center text-primary">
                    <div v-html="item.title" class="lh-sm mt-1"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        list:{
            type:Array,
            default: null,
        }
    },
    data(){
        return{

        }
    }
}
</script>

<style>

</style>